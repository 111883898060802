import { Container, Stack } from "@mui/material";
import { ReactNode } from "react";

export interface PageTemplateProps {
  children: ReactNode;
}

export const PageTemplate = ({ children }: PageTemplateProps) => {
  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <Stack spacing={3}>{children}</Stack>
    </Container>
  );
};
