import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["'Ibarra Real Nova Variable'", "serif"].join(","),
    fontWeightLight: 400,
    fontSize: 16,
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
  },
});
