import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { PageTemplate } from "../PageTemplate";
import { ContentCopy, ExpandMore } from "@mui/icons-material";

const paymentMethods: Record<string, Record<string, string>> = {
  "🇬🇧 Bank transfer in GBP": {
    "Account holder": "Maurice Yap",
    "Account number": "03191540",
    "Sort code": "20-79-29",
    IBAN: "GB53BUKB20792903191540",
    "SWIFT/BIC": "BUKBGB22",
  },
  "🇪🇺 Bank transfer in EUR": {
    "Account holder": "Maurice Lee Chin Yap",
    IBAN: "BE52 9673 6567 4909",
    "SWIFT/BIC": "TRWIBEB1XXX",
    "Bank address": "Wise, Rue du Trône 100, 3rd floor,Brussels, 1050, Belgium",
  },
  "🇺🇸 Bank transfer in USD": {
    "Account holder": "Maurice Lee Chin Yap",
    "ACH and Wire routing number": "026073150",
    "Account number": "8312249337",
    "Account type": "Checking",
    "SWIFT/BIC": "CMFGUS33",
    "Bank address":
      "Community Federal Savings Bank, 89-16 Jamaica Ave, Woodhaven NY 11421, United States",
  },
  "🇸🇬 Bank transfer in SGD": {
    "Payment network": "FAST",
    "Account holder": "Maurice Lee Chin Yap",
    "Bank name": "Wise Asia-Pacific Pte. Ltd.",
    "Bank code": "0516",
    "Account number": "117-802-02",
    "Bank address":
      "1 Paya Lebar Link #13-06 - #13-08 PLQ 2, Paya Lebar Quarter, Singapore 408533",
  },
  "💸 PayPal": {
    Link: "paypal.me/mauriceyap97",
  },
};

export const CashGifts = () => (
  <PageTemplate>
    <Typography variant="h2" component="h1">
      Cash gifts
    </Typography>
    <Typography variant="subtitle1" paragraph>
      Thank you so, so much for considering making a cash gift to us. You can do
      this in one of the following ways.
    </Typography>
    {Object.entries(paymentMethods).map(([method, details]) => (
      <Accordion key={method}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`${method}-content`}
          id={`${method}-header`}
        >
          <Typography variant="h5" component="h2">
            {method}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table size="small">
              {Object.entries(details).map(([description, value]) => (
                <TableRow key={description}>
                  <TableCell component="th" scope="row">
                    <Typography
                      sx={{
                        fontWeight: (theme) =>
                          theme.typography.fontWeightMedium,
                      }}
                    >
                      {description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <code>{value}</code>
                    <IconButton
                      sx={{ float: "right", verticalAlign: "baseline" }}
                      aria-label="copy"
                      size="small"
                      onClick={() => navigator.clipboard.writeText(value)}
                    >
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    ))}
  </PageTemplate>
);
