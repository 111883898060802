import React from "react";
import ReactDOM from "react-dom/client";

import "@fontsource-variable/ibarra-real-nova";
import "@fontsource-variable/ibarra-real-nova/wght-italic.css";

import { App } from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
