export const redirects: Array<[string, string]> = [
  ["/", "https://withjoy.com/maurice-and-becca"],
  ["/amazon", "https://www.amazon.co.uk/wedding/share/maurice-and-becca"],
  ["/rsvp", "https://withjoy.com/maurice-and-becca/rsvp"],
  ["/stream", "https://youtu.be/Bgo6Cep60y8"],
  [
    "/spotify",
    "https://open.spotify.com/playlist/3d5XezyqDus2ZAI3xyYVRj?si=3fa04fe6c3b0496f",
  ],
  [
    "/songs",
    "https://www.youtube.com/playlist?list=PLek2voRCRNPd4Hw29CqBVU0Vyd2_Tw6Ds",
  ],
  ["/photos", "https://photos.app.goo.gl/gmdybknHkpC3ZJ4W8"],
  ["/order", "https://drive.google.com/open?id=10z_YBwS8TxM8xiNuNQpNtLjY4O_1LHpg"],

  // All unknown routes go to the wedding website
  ["*", "https://withjoy.com/maurice-and-becca"],
];
