import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { redirects } from "./redirects";
import { CashGifts } from "./pages/CashGifts";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";

interface ExternalRedirectProps {
  href: string;
}

const ExternalRedirect = ({ href }: ExternalRedirectProps) => {
  useEffect(() => {
    window.location.replace(href);
  }, [href]);
  return null;
};

const router = createBrowserRouter([
  ...redirects.map(([path, href]) => ({
    path,
    element: <ExternalRedirect href={href} />,
  })),
  {
    path: "/cash-gifts",
    element: <CashGifts />,
  },
]);

export const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <RouterProvider router={router} />
  </ThemeProvider>
);
